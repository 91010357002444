import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useCountdownV2 } from '@st/i18n/composables/useCountDown'
import { isNullish, delay } from '@st/utils'
import claimAnimation from '../../assets/claim-animation.json'
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import type { RegularBonus } from '../../stores/useRegularBonusesStore'
import { useGetTitleByLevel } from '../../composables/useGetTitleByLevel'

export function useRegularBonus(bonus: Ref<RegularBonus>) {
  const { t } = useI18n()
  const router = useRouter()
  const { currencies } = storeToRefs(useCurrenciesStore())
  const { format } = useCryptoFormatter()
  const { refreshBonuses } = useRegularBonusesStore()

  const { isExpired } = useCountdownV2(
    computed(() => bonus.value.expiredAt ?? ''),
  )

  const bonusAmount = computed(() => format(bonus.value.amount || '0'))
  const isZeroAmount = computed(
    () => bonus.value.amount === '0' || !bonus.value.amount,
  )
  const bonusTimer = computed(() => {
    if (bonus.value.status === 'readyToClaim') {
      return {
        expiresAt: bonus.value.expiredAt,
        title: t('bonuses.timerTitle'),
      }
    }
    if (bonus.value.status === 'waitingForAccrual') {
      return {
        expiresAt: bonus.value.accrualAt,
        title: t('bonuses.timerAccrualTitle'),
      }
    }
    return null
  })
  const currencyIcon = computed(() => {
    const defaultIcon = 'c-crypto-icon-usdt' as const
    if (!bonus.value.currencyId) return defaultIcon
    return currencies.value[bonus.value.currencyId]?.icon ?? defaultIcon
  })
  const currencyCode = computed(() => {
    const defaultCode = 'USDT'
    if (!bonus.value.currencyId) return defaultCode
    return currencies.value[bonus.value.currencyId]?.code ?? defaultCode
  })
  const isHiddenAmount = computed(() => {
    if (bonus.value.type !== 'cashback') return false

    return isNullish(bonus.value.amount)
  })

  const { format: formatWithCurrency } = useCryptoFormatter({
    currency: currencyCode,
  })

  const bonusAmountWithCurrency = computed(() =>
    formatWithCurrency(bonus.value.amount ?? 0),
  )

  const { getTitle } = useGetTitleByLevel()
  const cashbackUrl = usePublicFileUrl('publicFileCashbackTerms')
  const rakeBackUrl = usePublicFileUrl('publicFileRakeBackTerms')
  const reloadBonusUrl = usePublicFileUrl('publicFileReloadBonusTerms')
  const ratRaceUrl = usePublicFileUrl('publicFileRatRaceTerms')
  const content = computed(() => {
    switch (bonus.value.type) {
      case 'ratRace':
        return {
          title: t('bonuses.tournament'),
          subtitle: '',
          link: ratRaceUrl.value,
        }
      case 'cashback':
        return {
          title: t('bonuses.weeklyCashback'),
          subtitle: t('bonuses.cashbackModalSubTitle'),
          link: cashbackUrl.value,
        }
      case 'rakeBack':
        return {
          title: t('bonuses.rakeback'),
          subtitle: t('bonuses.rakebackModalSubTitle'),
          link: rakeBackUrl.value,
        }
      case 'reload':
        return {
          title: getTitle(t('bonuses.reload'), bonus.value.level),
          subtitle: t('bonuses.reloadModalSubTitle'),
          link: reloadBonusUrl.value,
        }
      default:
        return {
          title: '',
          subtitle: '',
          link: '',
        }
    }
  })

  const {
    execute,
    status: claimStatus,
    error,
  } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value.bonusId ?? 0,
    })),
    immediate: false,
    watch: false,
  })

  async function handleBonusClaim() {
    try {
      await execute()
      // Wait for the animation to finish
      await delay(300)

      refreshBonuses()
    } catch (err) {
      console.error(err)
    }
  }
  async function handleBonusButtonClick() {
    if (bonus.value.status === 'readyToClaim') {
      await handleBonusClaim()
    } else {
      router.replace('/casino')
    }
  }

  const toast = useToast()

  watch(
    () => error.value,
    (newError) => {
      if (!newError) return
      toast.open({
        label: t('bonuses.error'),
        type: 'negative',
      })
    },
  )

  return {
    handleBonusButtonClick,
    claimStatus,
    bonusAmount,
    bonusAmountWithCurrency,
    isZeroAmount,
    currencyIcon,
    currencyCode,
    claimAnimation,
    isHiddenAmount,
    isExpired,
    bonusTimer,
    content,
  }
}
